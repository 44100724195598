import { Component } from '@angular/core';

@Component({
  selector: 'app-page-unavailable',
  standalone: true,
  imports: [],
  templateUrl: './page-unavailable.component.html',
  styleUrls: ['./page-unavailable.component.css'],
})
export class PageUnavailableComponent {}
