<lib-header />

<div class="questions">
  <ng-container *ngIf="page">
    <ng-container
      *ngFor="let question of page.questions; trackBy: trackByQuestionNumber"
    >
      <div class="tab">
        <p class="statement">
          {{ question.title }}
        </p>

        <div class="content">
          <ng-container
            *ngFor="
              let answer of question.answers;
              trackBy: trackByAnswerId;
              let first = first;
              let last = last
            "
          >
            <ng-container *ngIf="question.type === 1">
              <div
                class="answer"
                [ngClass]="{
                  active: question.value?.indexOf(answer.id) !== -1,
                  'type-2-no-img': !answer.img,
                  'two-selects': question.answers.length === 2,
                  'first-select': first && question.answers.length === 2,
                  'last-select': last && question.answers.length === 2,
                }"
                (click)="
                  question.value = [answer.id];
                  selectUniqueAnswer(page, answer.next)
                "
              >
                <ng-container *ngIf="answer.img">
                  <img [src]="answer.img" alt="IMG" />
                </ng-container>
                <p>{{ answer.description }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="question.type === 2">
              <div
                class="answer"
                [ngClass]="{
                  active: question.value?.indexOf(answer.id) !== -1,
                  'type-2-no-img': !answer.img,
                }"
                (click)="
                  addAnswerType2(question, answer.id); selectUniqueAnswer(page)
                "
              >
                <ng-container *ngIf="answer.img">
                  <img [src]="answer.img" alt="IMG" />
                </ng-container>
                <p>{{ answer.description }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="question.type !== 1 && question.type !== 2">
              <textarea
                matInput
                placeholder="Ex. Gostei muito..."
                [(ngModel)]="question.value"
                (ngModelChange)="
                  checkDisabled(page, true); question.value = [$event]
                "
                autofocus
              ></textarea>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showBtn">
      <div class="tab">
        <button
          class="confirm"
          (click)="selectPage(indexSelectedPage + 1)"
          [disabled]="disabled"
        >
          Enviar
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!page">
    <div class="end-page">
      <ng-container *ngIf="poll.endPage.img">
        <img [src]="poll.endPage.img" alt="IMG" />
      </ng-container>
      <ng-container *ngIf="!poll.endPage.img">
        <svg
          width="238"
          height="239"
          viewBox="0 0 238 239"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_715_1963)">
            <path
              opacity="0.32"
              d="M119 238.645C184.703 238.645 237.965 185.382 237.965 119.679C237.965 53.9764 184.703 0.713745 119 0.713745C53.2971 0.713745 0.0344238 53.9764 0.0344238 119.679C0.0344238 185.382 53.2971 238.645 119 238.645Z"
              fill="#C47D7F"
            />
            <path
              opacity="0.32"
              d="M167.044 211.191C168.307 211.191 169.332 212.215 169.332 213.479C169.332 214.652 168.448 215.619 167.311 215.751L167.044 215.767H70.9563C69.6927 215.767 68.6685 214.742 68.6685 213.479C68.6685 212.306 69.5516 211.339 70.6894 211.207L70.9563 211.191H167.044Z"
              fill="#C47D7F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M164.756 44.1819C168.546 44.1819 171.619 47.2547 171.619 51.0453V186.025C171.619 189.816 168.546 192.889 164.756 192.889H73.244C69.4535 192.889 66.3806 189.816 66.3806 186.025V51.0453C66.3806 47.2547 69.4535 44.1819 73.244 44.1819H164.756ZM151.029 57.9087H86.9708C83.1802 57.9087 80.1074 60.9815 80.1074 64.7721V165.435C80.1074 169.226 83.1802 172.299 86.9708 172.299H151.029C154.82 172.299 157.893 169.226 157.893 165.435V64.7721C157.893 60.9815 154.82 57.9087 151.029 57.9087Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M164.756 40.7502C170.442 40.7502 175.051 45.3595 175.051 51.0453V192.889C175.051 198.575 170.442 203.184 164.756 203.184H73.2441C67.5582 203.184 62.949 198.575 62.949 192.889V51.0453C62.949 45.3595 67.5582 40.7502 73.2441 40.7502H164.756ZM164.756 47.6136H73.2441C71.3488 47.6136 69.8124 49.1501 69.8124 51.0453V192.889C69.8124 194.784 71.3488 196.321 73.2441 196.321H164.756C166.651 196.321 168.188 194.784 168.188 192.889V51.0453C168.188 49.1501 166.651 47.6136 164.756 47.6136ZM155.605 54.477C158.764 54.477 161.324 57.0377 161.324 60.1965V170.011C161.324 173.17 158.764 175.73 155.605 175.73H82.3953C79.2365 175.73 76.6758 173.17 76.6758 170.011V60.1965C76.6758 57.0377 79.2365 54.477 82.3953 54.477H155.605ZM154.461 61.3404H83.5392V168.867H154.461V61.3404ZM141.878 152.852C143.773 152.852 145.31 154.389 145.31 156.284C145.31 158.068 143.949 159.534 142.208 159.7L141.878 159.716H96.122C94.2268 159.716 92.6903 158.179 92.6903 156.284C92.6903 154.5 94.0513 153.034 95.7915 152.868L96.122 152.852H141.878ZM141.878 140.269C143.773 140.269 145.31 141.806 145.31 143.701C145.31 145.485 143.949 146.951 142.208 147.117L141.878 147.133H96.122C94.2268 147.133 92.6903 145.596 92.6903 143.701C92.6903 141.917 94.0513 140.451 95.7915 140.285L96.122 140.269H141.878ZM141.878 127.687C143.773 127.687 145.31 129.223 145.31 131.118C145.31 132.902 143.949 134.368 142.208 134.534L141.878 134.55H96.122C94.2268 134.55 92.6903 133.014 92.6903 131.118C92.6903 129.334 94.0513 127.869 95.7915 127.702L96.122 127.687H141.878Z"
              fill="#941519"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M149.885 21.304C173.26 21.304 192.21 40.2532 192.21 63.6282C192.21 87.0033 173.26 105.952 149.885 105.952C145.545 105.952 141.358 105.299 137.416 104.086L112.814 116.566L116.229 89.2948C110.791 82.1751 107.561 73.2788 107.561 63.6282C107.561 40.2532 126.51 21.304 149.885 21.304Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M149.885 17.8722C124.594 17.8722 104.129 38.8902 104.129 64.772L104.141 65.8502C104.355 75.5365 107.447 84.7389 112.91 92.4047L113.214 92.8227L108.763 116.764L108.721 117.054C108.441 119.739 111.303 121.727 113.754 120.418L134.93 109.103L135.668 109.363C140.216 110.886 144.999 111.672 149.885 111.672C175.176 111.672 195.641 90.6539 195.641 64.772C195.641 38.8902 175.176 17.8722 149.885 17.8722ZM149.885 24.7356C171.344 24.7356 188.778 42.6403 188.778 64.772C188.778 86.9038 171.344 104.809 149.885 104.809C145.054 104.809 140.349 103.903 135.939 102.158L135.582 102.039C134.741 101.809 133.837 101.907 133.059 102.323L116.815 110.999L120.24 92.5964C120.42 91.6287 120.176 90.6303 119.57 89.8549C114.051 82.7965 110.993 74.031 110.993 64.772C110.993 42.6403 128.426 24.7356 149.885 24.7356Z"
              fill="#941519"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M149.617 38.4624C154.1 38.4624 157.972 41.2723 158.217 45.8265L158.229 46.2877L158.229 53.0014L169.234 53.0034C175.443 53.0034 177.758 60.2004 177.273 69.2684L177.189 70.5161C176.431 79.996 173.052 87.4816 166.875 87.4816C161.792 87.4816 152.161 86.1702 137.798 83.5458C137.17 84.3401 136.197 84.8495 135.106 84.8495H123.575C121.68 84.8495 120.144 83.3131 120.144 81.4178V56.4351C120.144 54.5398 121.68 53.0034 123.575 53.0034H135.106C136.17 53.0034 137.122 53.488 137.751 54.2488C137.871 54.0814 137.994 53.9068 138.115 53.7283C139.879 51.1319 141.004 48.2302 141.004 46.2877C141.004 41.4627 144.984 38.4624 149.617 38.4624ZM149.617 45.3258C148.421 45.3258 147.868 45.7427 147.868 46.2877C147.868 52.0926 143.254 60.031 138.537 62.9379L138.535 76.7007L139.199 76.8247C153.062 79.3532 162.348 80.6182 166.875 80.6182L167.027 80.4906C167.057 80.4631 167.093 80.4299 167.135 80.3897C167.549 79.9916 168.008 79.2877 168.444 78.323C169.4 76.2042 170.092 73.1692 170.348 69.9689C170.602 66.7959 170.401 63.7749 169.808 61.6882C169.589 60.92 169.336 60.3469 169.1 60.001L168.995 59.8648L154.797 59.8668C153.014 59.8668 151.548 58.5058 151.381 56.7656L151.366 56.4351V46.2877C151.366 45.7427 150.813 45.3258 149.617 45.3258ZM131.672 59.8648H127.005V77.9841H131.672V59.8648Z"
              fill="#941519"
            />
            <path
              d="M119 190.601C121.527 190.601 123.576 188.552 123.576 186.025C123.576 183.498 121.527 181.45 119 181.45C116.473 181.45 114.424 183.498 114.424 186.025C114.424 188.552 116.473 190.601 119 190.601Z"
              fill="#941519"
            />
          </g>
          <defs>
            <clipPath id="clip0_715_1963">
              <rect
                width="237.931"
                height="237.931"
                fill="white"
                transform="translate(0.0344238 0.713745)"
              />
            </clipPath>
          </defs>
        </svg>
      </ng-container>
      <p class="title">{{ poll.endPage.title }}</p>
      <p class="description">{{ poll.endPage.description }}</p>
    </div>
  </ng-container>
  <ng-container
    *ngIf="page && page.pageNumber !== 1 && page.showTimer && page.timer"
  >
    <div class="timer">
      <p>Tempo para responder: {{ time.getValue() }} seg.</p>
    </div>
  </ng-container>
  <ng-container *ngIf="!page && poll.endPage.showTimer">
    <div class="timer">
      <p>Timer: {{ time.getValue() }} seg.</p>
    </div>
  </ng-container>
  <div class="logo-marca">
    <img src="assets/img/logo.png" alt="logotipo" width="300" />
  </div>
</div>
